import React from 'react';
import {Link} from 'react-router-dom'

export default function ExploreFpvCampaign() {
    return (
        <div className="portfolio-sections ExploreFpvCampaign">
                <section id="explore-fpv-campaign-intro" className="portfolio-section-intro">
                    <div className="jumbotron jumbotron-image shadow portfolio-top-banner">
                        <div className="container mt-5 pb-5">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1 className="pt-5">Explore Fpv Campaign</h1>
                                    <h2>260x sales return on digital advertising spend</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            <section id="explore-fpv-campaign-content" className="portfolio-section-content">
                <div className="container mt-5 mb-5 pb-5 text-left">
                    <div className="row">
                        <div className="col-md-12 text-left">
                            <h3>Campaign info</h3>
                        </div>
                            <div className="col-md-6"><p>Period: Apr 1 - Jun 30 / 2020</p></div>
                            <div className="col-md-6"><p>EMPTY</p></div>
                            <div className="col-md-6">
                                <p>Objective:</p>
                                <li>Raise brand awareness by maximizing brand exposure in US, EU, CA & AU market</li>
                                <li>Re-introduced the hero product to the beginners</li>
                                <li>Increase sales volume & lower CPA</li>
                            </div>
                            <div className="col-md-6"><p>Audience:</p>
                                <li>People who interested in Fpv drone</li>
                                <li>People who intetested in remote controllers games or hobbies</li>
                                <li>Re-target people who viewed our Facebook or YouTube videos</li>
                            </div>
                            <div className="col-md-6"><p>Channels:</p>
                                <li>Facebook ads, live streaming, post</li>
                                <li>Google SEM, display, shopping ads</li>
                                <li>KOL</li>
                            </div>
                            <div className="col-md-6"><p>Tasks:</p>
                                <li>Campaign planning & execution</li>
                                <li>Video & artwork creation, editing & content write up</li>
                                <li> Ad placement & optimization</li>
                                <li>Audience creation, re-targeting </li>
                                <li>Event tracking implementation</li>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h3>Result</h3>
                            <p>Achieved 7 digits sales revenue with 260x ROAs</p>
                        </div>
                        <div className="col-md-12">
                            <h3>Background</h3>
                            <p>
                            The Team BlackSheep is one of the industry leading brand of the Fpv drone hobby. One of their hero product is going to be promoted and aims to help  beginners to get started their flight easily. Brand image will be created to new players as to promote the hobby and increase the market share.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <h3>Why success?</h3>
                            <p>All-rounded advertising in a short period of time</p>
                            <p>Suitable ad format with high quality video & image content</p>
                            <p>Conversion-driven search strategy with on-going manually optimization on ads keywords with sales-driven intention</p>
                        </div>
                        <div className="col-md-12">
                            <h3>Job references</h3>
                            <h4>Key video content creation & editing</h4>
                            <div className="col-md-6 mt-3 mb-3">
                                <div class="video-container">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/N0SfwHkhKq0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className="col-md-6 mt-3 mb-3">
                                <div class="video-container mt-3 mb-3">
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/5dYWhnzPBAU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12">
                            <h4 className="mt-5">Ads content</h4>
                            <div className="row">
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "auto"}} 
                                    src="../img/portfolio/explore/explore-fpv-campaign-fb-video-ad.png" alt="Explore Fpv Facebook video ad" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "auto"}} 
                                    src="../img/portfolio/explore/explore-fpv-campaign-fb-conversion-ad.png" alt="Explore Fpv campaign Facebook conversion ad" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "350px"}} 
                                    src="../img/portfolio/explore/explore-fpv-campaign-fb-image-ad.png" alt="Explore Fpv Facebook ad link ad" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "auto"}} 
                                    src="../img/portfolio/explore/explore-fpv-google-display-ad-cta-button.png" alt="Explore Fpv campaign Google display ads with cta button" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "auto"}} 
                                    src="../img/portfolio/explore/explore-fpv-google-display-ad.png" alt="Explore Fpv campaign Google display ad" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "350px"}} 
                                    src="../img/portfolio/explore/explore-fpv-google-display-ad-cta-write-up.png" alt="Explore Fpv campaign Google display ad with cta write up" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "auto"}} 
                                    src="../img/portfolio/explore/youtube-ad-mock-up-mobile.png" alt="Explore Fpv YouTube ad mobile mock up" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "350px"}} 
                                    src="../img/portfolio/explore/youtube-ad-mock-up.png" alt="Explore Fpv YouTube ad desktop mock up" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "auto"}} 
                                    src="../img/portfolio/explore/youtube-ad-screen-shot.png" alt="Explore Fpv" />
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "auto"}} 
                                    src="../img/portfolio/explore/explore-fpv-campaign-google-search-ad.png" alt="Explore Fpv campaign Google search ad" />
                                </div>
                                
                            </div>
                            <h4 className="mt-5">Targeting audience & tracking</h4>
                            <div className="row">
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "350px"}} src="../img/portfolio/explore/explore-fpv-campaign-fb-tracking.png" alt="Explore Fpv campaign - Facebook tracking code implemenation"/>
                                </div>
                                <div className="col-md-6 mt-3 mb-3">
                                    <img  src="../img/portfolio/explore/explore-fpv-re-targeting-audience.png" alt="Explore Fpv campaign - Facebook retarget audience"/>
                                </div>
                            </div>
                            <h4 className="mt-5">Image creation</h4>
                            <div className="row">
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "350px"}} src="../img/portfolio/explore/drone-product-all.png" alt="Drone product full set"/>
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "350px"}} src="../img/portfolio/explore/drone-product-front.png" alt="Drone product front-side"/>
                                </div>
                                <div className="col-md-4 mt-3 mb-3">
                                    <img style={{width: "350px", height: "350px"}} src="../img/portfolio/explore/drone-product-set.png" alt="Drone product simple pack"/>
                                </div>
                            </div>
                        
                        </div>

                    
                  
                </div>
            </section>
 
        

           
        </div>
    );
}