import React from 'react';
import Stepper from './Steppers';

export default function FreelancesContent() {
    return (
        <div className="FreelancesContent">
            <section id="Freelancesfolio">
                <div className="container mt-5 mb-5 pb-5">
                    <div className="row">
                        <div className="col-md-12 freelances-steps">
                            <Stepper />
                        </div>
                    </div>
                    
                    
                  
                </div>
            </section>
        </div>
    );
}