import React from 'react';
import {Link} from 'react-router-dom'

import MenuIcon from '@material-ui/icons/Menu';

export default function Header() {
    return (
        <div className="Header">
            <header>
                <div className="container">
                    <nav className="navbar navbar-expand-lg navbar-default navbar-fixed-top">
                        <a className="navbar-brand" href="/#">
                            <Link to="/"><img src={'./img/navbar/logo.svg'} alt='Matthew Tang Logo' /></Link>
                        </a>
                        <button className="navbar-toggler menu-icon-outer" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon text-center"><MenuIcon className="menu-icon"/></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/#" >Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about" >About </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/portfolios" >Portfolios </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/freelances" >Freelances </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact" >Contact </Link>
                                </li>
                            </ul>

                            <ul className="navbar-nav ml-auto nav-flex-icons navbar-right">
                                <li className="nav-item">
                                    <Link 
                                    className="nav-link nav-link-social waves-effect waves-light nav-icon" 
                                    to={{ pathname: "https://www.facebook.com/matthewtang.digital/" }} target="_blank" rel="noreferrer">
                                        <img src="img/navbar/Vector-5.svg" alt="Facebook Icon" />
                                    </Link>
                                    <Link 
                                    className="nav-link nav-link-social waves-effect waves-light nav-icon" 
                                    to={{ pathname: "https://www.instagram.com/matthewtang.digital/" }} target="_blank" rel="noreferrer">
                                        <img src="img/navbar/Vector-4.svg" alt="Instagram Icon" />
                                    </Link>
                                    <Link 
                                    className="nav-link nav-link-social waves-effect waves-light nav-icon" 
                                    to={{ pathname: "https://github.com/matthewhltang" }} target="_blank" rel="noreferrer">
                                        <img src="img/navbar/Vector-3.svg" alt="Github Icon" />
                                    </Link>
                                    <Link 
                                    className="nav-link nav-link-social waves-effect waves-light nav-icon" 
                                    to={{ pathname: "https://www.freecodecamp.org/fcca6da602f-dfa0-499e-aadd-a90d840e87c9" }} target="_blank" rel="noreferrer">
                                        <img src="img/navbar/Vector-1.svg" alt="Freecodecamp Icon" />
                                    </Link>
                                    <Link 
                                    className="nav-link nav-link-social waves-effect waves-light nav-icon" 
                                    to={{ pathname: "https://codepen.io/matthewhltang4work/pen/VwjQdRM" }} target="_blank" rel="noreferrer">
                                        <img src="img/navbar/Vector-2.svg" alt="Codepen Icon" />
                                    </Link>
                                    <Link 
                                        className="nav-link nav-link-social waves-effect waves-light" 
                                        to={{ pathname: "https://hk.linkedin.com/in/hon-lam-matthew-tang-64ba68115" }} 
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        <img src="img/navbar/Vector.svg" alt="Linkedin Icon"/>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    );
}
