import React from 'react';
import {Link} from 'react-router-dom'


export default function Footer() {
    return (
        <div className="footer" id="footer">
            <footer>
                <div className="container">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-3 text-center">
                                <h5 className="sub-title">Info</h5>
                                <Link to="/freelances"><div className="item">Freelances</div></Link>
                                <Link to="/about" ><div className="item">About</div></Link>
                                <Link className="item" to="/portfolios"><div className="item">Portfolios</div></Link>
                                <Link className="item" to="/contact"><div className="item">Contact</div></Link>
                            </div>
                            <div className="col-md-6 text-center">
                                <h4 className="title"><img src={'./img/navbar/logo.svg'} alt='Matthew Tang Logo' /> MATTHEW TANG</h4>
                                <div className="item">
                                    <Link 
                                        className="nav-link nav-link-social waves-effect waves-light" 
                                        to={{ pathname: "https://www.instagram.com/matthewtang.digital/" }} 
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        <img src="img/footer/instagram-icon.svg" alt="Instagram icon" />
                                    </Link>
                                    <Link 
                                        className="nav-link nav-link-social waves-effect waves-light" 
                                        to={{ pathname: "https://www.facebook.com/matthewtang.digital/" }} 
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        <img src="img/footer/facebook-icon.svg" alt="Facebook icon" />
                                    </Link>
                                    <Link 
                                        className="nav-link nav-link-social waves-effect waves-light" 
                                        to={{ pathname: "https://github.com/matthewhltang" }} 
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        <img src="img/footer/github-icon.svg" alt="Github icon" />
                                    </Link>
                                    <Link 
                                        className="nav-link nav-link-social waves-effect waves-light" 
                                        to={{ pathname: "https://www.youtube.com/channel/UCdIs9GVLj4-Zty4N-4VXD1Q" }} 
                                        target="_blank" 
                                        rel="noreferrer"
                                    >
                                        <img src="img/footer/youtube-icon.svg" alt="YouTube icon" />
                                    </Link>
                                </div>


                                <div className="form-content-right">
                                    <form className="form">
                                        <h6 className="item mt-2">Get started with us today!</h6>
                                    </form>
                                    <div className="form-inputs">
                                        <label htmlFor='email' className='form-label footer-email-registration'>
                                        </label>
                                            <input className="email-text" type="text" id="email" name="email" onchange="hideIcon(this);" Placeholder=" Subscribe newsletter" />
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 text-center">
                                <h5 className="sub-title">Contact</h5>
                                <div className="item">Email: <a href="mailto:business@matthew-tang.com"> matthewhltang4gmail.com</a></div>
                                <div className="item">Phone: +852  91816658</div>
                                <Link
                                    to={{ pathname: "https://wa.me/91816658" }} 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    <div className="item">WhatsApp: +852 91816658</div>
                                </Link>
                                <Link
                                    to={{ pathname: "http://m.me/matthewtang.digital" }} 
                                    target="_blank" 
                                    rel="noreferrer"
                                >
                                    <div className="item">FB Inbox: @matthewtang.digital</div>
                                </Link>
                                </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="item">Copyright © 2021 , Matthew Tang. All rights reserved</div>
                    </div>
                </div>
            </footer>
        </div>
    );
}