import React from 'react';
import PortfolioIntro from './portfolios/PortfolioIntro';
import PortfolioContent from './portfolios/PortfolioContent';



export default function Portfolios() {
    return (
        <div className="Portfolio">
            <PortfolioIntro />
            <PortfolioContent />
        </div>
    );
}