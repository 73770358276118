import React from 'react';

export default function FreelancesPriceList() {
    return (
        <div className="FreelancesPriceList">
            <section id="FreelancesPriceList">
            <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-5 text-center">
                            <div className="row">
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-consulation.svg" alt="Consulation Icon" className="box-icon" />
                                        <h4 className="mt-3">Fixed Amount</h4>
                                        <p className="description mt-4">Suitable for clients with clear instructions of what they needed. Predictable cost would be easier for clients to budget thier projects.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-social-media-advertising.svg" alt="Social Media Advertising Icon" className="box-icon" />
                                        <h4 className="mt-3">Hourly Rate</h4>
                                        <p className="description mt-4">Suitable clients who are not clear for scope of work, or how big a project will be.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-google-advertising.svg" alt="Google Advertising Icon" className="box-icon" />
                                        <h4 className="mt-3">Commission Based</h4>
                                        <p className="description mt-4">Suitable for clients who have nice branding and strong motiviation to push thier sales as the objective.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    );
}