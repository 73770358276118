import React from 'react';

export default function HomeSecond() {
    return (
        <div className="HomeSecond">
            <section id="second">
                <div className="container mt-5 mb-5 pb-5">
                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <h3>About Me</h3>
                            <p>-Nothing is impossible, if you believe -</p>
                            <div className="row">
                                <div className="col-md-4 small-box-outer">
                                    <img src="img/home/left.png" alt="Social Media Marketing, Search Engine Marketing, Content Creation" />
                                </div>
                                <div className="col-md-4 small-box-outer">
                                    <img src="img/home/matthew-tang-profile-2.png" alt="My profile pic" />
                                </div>
                                <div className="col-md-4 small-box-outer">
                                <img src="img/home/right.png" alt="SEO, web development, tracking" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}