import React from 'react';

import AboutIntro from './about/AboutIntro';
import AboutContent from './about/AboutContent';


export default function About() {
    return (
        <div className="Home">
            <AboutIntro />
            <AboutContent />

        </div>
    );
}