import React from 'react';
import {Link} from 'react-router-dom'

export default function PortfolioContent() {
    return (
        <div className="PortfolioContent">
                <div className="HomePortfolio">
            <section id="HomePortfolio">
                <div className="container mt-5 mb-5 pb-5">
                    <div className="row">
                        <div className="col-md-4">
                                <Link className="nav-link" to="/portfolios/explorefpvcampaign" > 
                                    <img src="img/home/portfolios/explore-fpc-campaign.png" alt="Explore FPV Campaign"/>
                                    <h5>Explore FPV Campaign</h5>
                                    <p>Team BlackSheep</p>
                                </Link>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/racing-in-the-hotel-campaign.png" alt="Racing in the Hotel Campaign"/>
                            <h5>Racing in the Hotel Campaign</h5>
                            <p>Team BlackSheep | Drones</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/long-range-campaign.png" alt="Long Range Heaven Campaign"/>
                            <h5>Long Range Heaven Campaign</h5>
                            <p>Team BlackSheep | Drones</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/on-going-social-media-management-tbs.png" alt="On-going social media management"/>
                            <h5>On-going Social Media Management</h5>
                            <p>Team BlackSheep | Drones</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/landing-page-design.png" alt="On-going social media management"/>
                            <h5>Landing Page Development</h5>
                            <p>Team BlackSheep | Drones</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/coming-soon.png" alt="New website development"/>
                            <h5>New Website Development</h5>
                            <p>Team BlackSheep | Drones</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/marketing-automation.png" alt="CRUD Marketing Automation"/>
                            <h5>Develops CRUD Marketing Automation</h5>
                            <p>Team BlackSheep | Drones</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/erp-system-development.png" alt="ERP system development"/>
                            <h5>ERP System Development</h5>
                            <p>Team BlackSheep | Drones</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/seo-tracking-enhancement.png" alt="SEO tracking & enhancement"/>
                            <h5>SEO Tracking & Enhancement</h5>
                            <p>Team BlackSheep | Drones</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/mum-and-babies-campaign.png" alt="Mums and Babies Campaign"/>
                            <h5>Mum & Babies Campaign</h5>
                            <p>CATALO | Health Supplment</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/social-media-management.png" alt="CATALO On-going social media management"/>
                            <h5>On-going Social Media Management</h5>
                            <p>CATALO | Health Supplment</p>
                        </div>
                        <div className="col-md-4">
                            <img src="img/home/portfolios/e-commerce-site-revamped.png" alt="Magento 2 E-commerce website revamped project"/>
                            <h5>Magento2 E-commerce Website Revamped Project</h5>
                            <p>CATALO | Health Supplment</p>
                        </div>
                    </div>
                    
                  
                </div>
            </section>
        </div>
        
        </div>
    );
}