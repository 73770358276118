import React from 'react';

export default function HomeStatus() {
    return (
        <div className="HomeStatus">
            <section id="HomeStatus">
                
                <div className="container mt-5 mb-5 pb-5">
                    <div className="row">
                        <div className="status-card col-md-12 text-center">
                            <div className="row">
                                <div className="col-md-3 small-box-outer">
                                    <div className="box">
                                        <img src="img/home/status/happy-clients-icon.svg" alt="Consulation Icon" className="box-icon" />
                                        <p className="numbers">24+</p>
                                        <p className="sub-titles">Happy Clients</p>
                                    </div>
                                </div>
                                <div className="col-md-3 small-box-outer">
                                    <div className="box">
                                        <img src="img/home/status/completed-projects-icon.svg" alt="Consulation Icon" className="box-icon" />
                                        <p className="numbers">84+</p>
                                        <p className="sub-titles">Projects Completed</p>
                                    </div>
                                </div>
                                <div className="col-md-3 small-box-outer">
                                    <div className="box">
                                        <img src="img/home/status/coffees-drink-icon.svg" alt="Consulation Icon" className="box-icon" />
                                        <p className="numbers">300+</p>
                                        <p className="sub-titles">Coffees drink</p>
                                    </div>
                                </div>
                                <div className="col-md-3 small-box-outer">
                                    <div className="box">
                                        <img src="img/home/status/tasks-output.svg" alt="Co   nsulation Icon" className="box-icon" />
                                        <p className="numbers">548+</p>
                                        <p className="sub-titles">Tasks Ouput</p>
                                    </div>
                                </div>
                            </div>
                        </div>                     
                    </div>
                
                </div>
        </section>
    </div>
    )
}