import React from 'react';

export default function FreelancesPaymentMethods() {
    return (
        <div className="FreelancesPaymentMethods">
            <section id="FreelancesPaymentMethods">
            <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center mt-5">
                            <div className="row">
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-consulation.svg" alt="Consulation Icon" className="box-icon" />
                                        <h4 className="mt-3">FPS</h4>
                                        <p className="description mt-4">General use of e-payment which connected traditional bank with electronic / digital payments in Hong Kong.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-social-media-advertising.svg" alt="Social Media Advertising Icon" className="box-icon" />
                                        <h4 className="mt-3">PayMe</h4>
                                        <p className="description mt-4">We accpet PayMe payment which is one of the popular payment for e-commerce or digital business in Hong Kong.</p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-google-advertising.svg" alt="Google Advertising Icon" className="box-icon" />
                                        <h4 className="mt-3">Paypal</h4>
                                        <p className="description mt-4">We are welcoming any the international business to growth and develop the business for the Hong Kong local or global markets.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    );
}