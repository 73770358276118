import React from 'react';

export default function PortfolioIntro() {
    return (
        <div className="PortfolioIntro">
            <section id="portfolio-intro">
                <div className="jumbotron jumbotron-image shadow portfolio-top-banner">
                    <div className="container mt-5 pb-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1 className="pt-5">Portfolio</h1>
                                <h2>Unleash your creativity</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}