import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Home from './components/Home';
import About from './components/About';
import Portfolios from './components/Portfolios';
import Contact from './components/Contact';
import Freelances from './components/Freelances';
import Header from './components/Header';
import Footer from './components/Footer';
import ExploreFpvCampaign from './components/portfolios/ExploreFpvCampaign';

class App extends React.Component {
  render() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Switch>
                
                  <Route exact path="/" component={Home} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/portfolios" component={Portfolios} />
                  <Route exact path="/freelances" component={Freelances} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/portfolios/explorefpvcampaign">
                      <ExploreFpvCampaign />
                  </Route>
                </Switch>
                <Footer />
            </div>
        </Router>
    );
  }
}
export default App;
