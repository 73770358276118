import React from 'react';
import ContactIntro from './contact/ContactIntro';
import useForm from './contact/useForm';



export default function Contact() {
    const {handleChange, values, handleSubmit} = useForm();


    return (
        <div id="Contact" className="Contact">
            <ContactIntro />
            <div className="form-content-right">
                <form className="form" onSubmit={handleSubmit}>
                    <h6>Get started with us today!</h6>
                    <div className="form-inputs">
                        <label htmlFor='username' className='form-label'>
                            Username
                        </label>
                        <input 
                            id='username' 
                            type='text' 
                            name='username' 
                            className='form-input' 
                            placeholder='Enter your username' 
                            value={values.username} 
                            onChange={handleChange} 
                        />
                    </div>
                    <div className="form-inputs">
                        <label htmlFor='email' className='form-label'>
                            Email
                        </label>
                        <input id='email' type='email' name='email' className='form-input' placeholder='Enter your email' 
                        value={values.email} 
                        onChange={handleChange} 
                        />
                    </div>
                    <div className="form-inputs">
                        <label htmlFor='password' className='form-label'>
                            Password
                        </label>
                        <input id='password' type='password' name='password' className='form-input' placeholder='Enter your password' 
                        value={values.passwprd} 
                        onChange={handleChange} 
                        />
                    </div>
                    <div className="form-inputs">
                        <label htmlFor='password2' className='form-label'>
                            Password 2
                        </label>
                        <input id='password2' type='password' name='password2' className='form-input' placeholder='Enter your password 2' 
                        value={values.password2} 
                        onChange={handleChange} 
                        />
                    </div>
                    <button className="form-input-btn" type="submit">
                        Send
                    </button>
                    <span className="form-input-login">
                        Already have an account? Login <a href="/#">here</a>
                    </span>
                </form>
            </div>
        </div>
    );
}