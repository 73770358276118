import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function HomeServices() {
    return (
        <div className="HomeServices">
            <section id="HomeServices">
            <div className="container mt-5 mb-5 pb-5">
                <div className="row">
                    <div className="col-md-12 text-center pt-5">
                        <h3>Services</h3>
                        <p>Digital marketing services you may look for</p>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <div className="row">
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-consulation.svg" alt="Consulation Icon" className="box-icon" />
                                        <h4>Consulation</h4>
                                        <p className="description">Feels frustration with marketing? One step to get started a brand review on  positioning & sales funnel and having more marketing insights.</p>
                                        <Link to="/portfolios"><p className="cta">Explore <ArrowForwardIosIcon /></p></Link>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-social-media-advertising.svg" alt="Social Media Advertising Icon" className="box-icon" />
                                        <h4>Social Media Advertising</h4>
                                        <p className="description">Ability to achieve what you aim for. Hyper-direct way to reach out the new customers you want. Increase sales, drive web traffic, no. of new members.</p>
                                        <Link to="/portfolios"><p className="cta">Explore <ArrowForwardIosIcon /></p></Link>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-google-advertising.svg" alt="Google Advertising Icon" className="box-icon" />
                                        <h4>Google Advertising</h4>
                                        <p className="description">Most of the customers will search in Google before their purchase. Google SERP coverage is a must-have advertising you needed.</p>
                                        <Link to="/portfolios"><p className="cta">Explore <ArrowForwardIosIcon /></p></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-5">
                            <div className="box">
                            <img src="img/home/services/services-website-development.svg" alt="Consulation Icon" className="box-icon" />
                            <h4>Web Development</h4>
                            <p className="description">Despite retail exposure dominance most of the Hong Kong local market, brand website  brings an on-going growth and trustworthy .</p>
                            <Link to="/portfolios"><p className="cta">Explore <ArrowForwardIosIcon /></p></Link>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className="box">
                                <img src="img/home/services/services-content-creation.svg" alt="Social Media Advertising Icon" className="box-icon" />
                                <h4>Content Creation</h4>
                                <p className="description">Time to reach your potential customers differently.  Be creative, let's try 3D ads with your social media networks. </p>
                                <Link to="/portfolios"><p className="cta">Explore <ArrowForwardIosIcon /></p></Link>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className="box">
                                <img src="img/home/services/services-event-tracking.svg" alt="Google Advertising Icon" className="box-icon" />
                                <h4>Event Tracking setup</h4>
                                <p className="description">Set up the tracking to analysis your campaigns and marketing channels' effectiveness, especially for brands with e-commerce business.</p>
                                <Link to="/portfolios"><p className="cta">Explore <ArrowForwardIosIcon /></p></Link>
                            </div>
                        </div>
                    </div>
                        
                    <div className="row">
                        <div className="col-md-12">
                            <Button variant="contained" className="explore">
                                <Link to="/freelances" class="explore-text">Learn more <ArrowForwardIosIcon /></Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}