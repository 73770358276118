import React from 'react';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function HomeIntro() {
    return (
        <div className="HomeIntro">
            <section id="intro">
                <div className="jumbotron jumbotron-image shadow home-top-banner">
                    <div className="container mt-5 mb-5 pb-5">
                        <div className="row">
                            <div className="col-md-12 text-center pt-5 mb-5 pt-4">
                                <h1 className="pb-4">Matthew Tang</h1>
                                <h2 className="pb-2">Digital Marketing Specialist</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="col-md-6"></div>
                                <Button variant="contained" className="get-started-button" >
                                    <Link to="/contact">
                                        <span className="get-started-text">Get started </span>
                                        <span className="get-started-arrow-icon"><ArrowForwardIosIcon /></span>
                                    </Link>
                                </Button>
                                <Button variant="contained" className="explore-all-button">
                                        <Link to="/portfolios">
                                            <span className="explore-all-text">Explore all</span> 
                                            <span className="get-started-arrow-icon"><ArrowForwardIosIcon /></span>
                                        </Link>
                                    </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}