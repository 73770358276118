import React from 'react';

export default function ContactIntro() {
    return (
        <div className="ContactIntro">
            <section id="contact-intro">
                <div className="jumbotron jumbotron-image shadow contact-top-banner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1>Contact <br />(Development stage)</h1>
                                <p className="lead">Just a click to start our conversations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}