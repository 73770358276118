import React from 'react';

export default function AboutIntro() {
    return (
        <div className="AboutIntro">
            <section id="about-intro">
                <div className="jumbotron jumbotron-image shadow about-top-banner">
                    <div className="container mt-5 pb-5">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <h1 className="pt-5">About</h1>
                                <h2>Nothing is impossible, if you believe."</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}