import React from 'react';

import HomeIntro from './home/HomeIntro';
import HomeSecond from './home/HomeSecond';
import HomePortfolio from './home/HomePortfolio';
import HomeStatus from './home/HomeStatus';
import HomeExperiences from './home/HomeExperiences';
import HomeServices from './home/HomeServices';

export default function Home() {
    return (
        <div className="Home">
            <HomeIntro />
            <HomeSecond />
            <HomeStatus />
            <HomeExperiences />
            <HomeServices />
            <HomePortfolio />
        </div>
    );
}

