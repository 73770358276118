import React from 'react';
import FreelancesIntro from './freelances/FreelancesIntro';
import FreelancesContent from './freelances/FreelancesContent';

export default function Freelances() {
    return (
        <div className="Freelances">
            <FreelancesIntro />
            <FreelancesContent />
        </div>
    );
}