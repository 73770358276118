import React from 'react';

export default function HomeExperiences() {
    return (
        <div className="HomeExperiences">
            <section id="HomeExperiences">
                <div className="container mt-5 mb-5 pb-5">
                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <h3>Experiences</h3>
                            <p>4Yrs Experiences in Global & Local Markets</p>
                            </div></div>
                            <div className="row">
                                <div className="col-md-12">
                                    <img src="img/home/Experiences-countries.png" alt="Countries"/>
                                </div>
                                <hr className="divider"/>
                            </div>
                            
                            <div className="row">
                                <div className="col-md-12">
                                    <img src="img/home/Experiences-brands.png" alt="Countries"/>
                                </div>
                            
                            <hr className="divider" />
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>MNC | FMCG | Listing Companies | SME</p>
                                </div>
                            </div>
                            
                     
                </div>
            </section>
        </div>
    );
}