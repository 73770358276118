import React from 'react';
import {Link} from 'react-router-dom';

export default function AboutContent() {
    return (
        <div className="AboutContent">
            <section id="about-content">
            <div className="container mt-5 mb-5 pb-5">
                <div className="row">
                    <div className="col-md-12 text-center pt-5">
                        <h3>Skills: Marketing Planning</h3>
                        <p>Achieved 200x Return on Marketing Spent</p>
                    </div>
                </div>
                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <div className="row">
                                <div className="col-md-6 mb-5">
                                    <div className="box">
                                    <h4 className="pt-4 pb-4">Digital Calendar</h4>
                                        <p className="description">Align the communication with thematic, festives and gimmicks.</p>
                                        <Link to="/img/about/digital-calendar.png" target="_blank"><img src="img/about/digital-calendar.png" alt="Marketing Planning"/></Link>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">Tasks List</h4>
                                        <p className="description">Plan & execute all marketing activities on schedule.</p>
                                        <Link to="img/about/tasks-list.png" target="_blank"><img src="img/about/tasks-list.png" alt="Tasks List"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <h3>Skills: Customize Tracking</h3>
                            <p>Customize tracking on Paid Social Media & Search Ads</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <Link to="img/about/customize-tracking.png" target="_blank"><img src="img/about/customize-tracking.png" alt="Customize Tracking"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   

                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <h3>Skills: Sales Funnel Optimization</h3>
                            <p>Experiences on e-commerce tracking & improving</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <Link to="img/about/sales-funnel-optimization.png" target="_blank"><img src="img/about/sales-funnel-optimization.png" alt="Sales Funnel Optimization"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <Link to="img/about/sales-funnel-optimization-finalize.png" target="_blank"><img src="img/about/sales-funnel-optimization-finalize.png" alt="Sales Funnel Optimization"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   

                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <h3>Skills: Insights on Budget Split</h3>
                            <p>Spend more time & money on top funnels</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <Link to="img/about/marketing-funnels-budgets.png" target="_blank"><img src="img/about/marketing-funnels-budgets.png" alt="Budget Splits"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <Link to="img/about/top-marketing-funnels-budgets.png" target="_blank"><img src="img/about/top-marketing-funnels-budgets.png" alt="Top marketing funnels budget optmized"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    
                    <div className="row">
                        <div className="col-md-12 text-center pt-5">
                            <h3>Interests: Latest Techs</h3>
                            <p>Explore Figma, latest web design tool</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">Figma</h4>
                                        <p className="description">Latest web design tool</p>
                                        <Link to="img/about/figma.png" target="_blank"><img src="img/about/figma.png" alt="Figma design tool"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">Figma</h4>
                                        <p className="description">Export web design mock up to HTML</p>
                                        <Link to="img/about/figma-html.png" target="_blank"><img src="img/about/figma-html.png" alt="Figma to HTML"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div className="row">
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">SparkAR Face detection for FB ads</h4>
                                        <p className="description">With 3D rendering object surrounding with loops math logics</p>
                                        <Link to="img/about/SparkAR-3d-objects.gif" target="_blank"><img src="img/about/SparkAR-3d-objects.gif" alt="SparkAR 3D object rendering"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">SparkAR Face detection for IG stories</h4>
                                        <p className="description">Displays random images based on random function</p>
                                        <Link to="img/about/SparkAR-face-detection.gif" target="_blank"><img src="img/about/SparkAR-face-detection.gif" alt="SparkAR face detection"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div className="row">
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">SparkAR product packing rendering</h4>
                                        <p className="description">Improves the product branding and engagement with customers</p>
                                        <Link to="img/about/sparkar-product-packing-rendering.gif" target="_blank"><img src="img/about/sparkar-product-packing-rendering.gif" alt="SparkAR product packing rendering"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">SparkAR product packing with text rendering</h4>
                                        <p className="description">Improves the after sales experiences for customers</p>
                                        <Link to="img/about/sparkar-product-packing-rendering-thankyou.gif" target="_blank"><img src="img/about/sparkar-product-packing-rendering-thankyou.gif" alt="SparkAR product packing rending with wordings"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="row">
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">Latest 2021 React component design</h4>
                                        <p className="description">Exploring the new trend of React Glassmorphism component design</p>
                                        <Link to="img/about/react-glass-design.png" target="_blank"><img src="img/about/react-glass-design.png" alt="React glassmorphism component design"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center pt-5">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="box">
                                        <h4 className="pt-4 pb-4">Showing responsive 3D product in browser</h4>
                                        <p className="description">Improves e-commerce product awareness with responsive 3D products</p>
                                        <Link to="img/about/responsive-3d-product-in-browser.gif" target="_blank"><img src="img/about/responsive-3d-product-in-browser.gif" alt="Responsive product in browser"/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  

                    



                </div>
            </section>
        </div>
    );
}