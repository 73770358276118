import React from 'react';

export default function FreelancesServices() {
    return (
        <div className="FreelancesServices">
            <section id="FreelancesServices">
            <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-5 text-center">
                            <div className="row">
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-consulation.svg" alt="Consulation Icon" className="box-icon" />
                                        <h4 className="mt-2">Consulation</h4>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-social-media-advertising.svg" alt="Social Media Advertising Icon" className="box-icon" />
                                        <h4 className="mt-2">Social Media Advertising</h4>
                                        </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-google-advertising.svg" alt="Google Advertising Icon" className="box-icon" />
                                        <h4 className="mt-2">Google Advertising</h4>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-5">
                            <div className="box">
                            <img src="img/home/services/services-website-development.svg" alt="Consulation Icon" className="box-icon" />
                            <h4 className="mt-2">Web Development</h4>
                            </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className="box">
                                <img src="img/home/services/services-content-creation.svg" alt="Social Media Advertising Icon" className="box-icon" />
                                <h4 className="mt-2">Content Creation</h4>
                                </div>
                        </div>
                        <div className="col-md-4 mb-5">
                            <div className="box">
                                <img src="img/home/services/services-event-tracking.svg" alt="Google Advertising Icon" className="box-icon" />
                                <h4 className="mt-2">Event Tracking setup</h4>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}