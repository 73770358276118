import React from 'react';
import {Link} from 'react-router-dom';

export default function FreelancesContact() {
    return (
        <div className="FreelancesContact">
            <section id="FreelancesContact">
            <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center mt-5">
                            <div className="row">
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-consulation.svg" alt="Consulation Icon" className="box-icon" />
                                        <h4 className="mt-3">Facebook Inbox</h4>
                                        <p className="description mt-4">@matthewtang.digital</p>
                                        <Link
                                            to={{ pathname: "http://m.me/matthewtang.digital" }} 
                                            target="_blank" 
                                            rel="noreferrer"
                                        >
                                            <div className="item">Messenge me</div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-social-media-advertising.svg" alt="Social Media Advertising Icon" className="box-icon" />
                                        <h4 className="mt-3">WhatsApp</h4>
                                        <p className="description mt-4">+852 91816658</p>
                                        <Link
                                            to={{ pathname: "https://wa.me/91816658" }} 
                                            target="_blank" 
                                            rel="noreferrer"
                                        >
                                            <div className="item">WhatsApp me</div>
                                        </Link>
                                       </div>
                                </div>
                                <div className="col-md-4 mb-5">
                                    <div className="box">
                                        <img src="img/home/services/services-google-advertising.svg" alt="Google Advertising Icon" className="box-icon" />
                                        <h4 className="mt-3">Email</h4>
                                        <p className="description mt-4">matthewhltang4gmail.com</p>
                                        <div><a href="mailto:business@matthew-tang.com">Email me</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    );
}